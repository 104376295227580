@import 'variables';

._btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: max-content;
  border: 2px solid transparent;
  border-radius: 56px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  // Color

  &.black {
    background-color: $black;
    color: $white;

    @media (hover: hover) {
      &:hover {
        &:not(:disabled) {
          background-color: $white;
          border-color: $black;
          color: $black;
        }
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: $white;
        border-color: $black;
        color: $black;
      }
    }
  }

  &.white {
    background-color: $white;
    color: $black;

    @media (hover: hover) {
      &:hover {
        &:not(:disabled) {
          background-color: $black;
          border-color: $white;
          color: $white;
        }
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: $black;
        border-color: $white;
        color: $white;
      }
    }
  }

  &.outline {
    border-color: $black;
    color: $black;
    background-color: transparent;

    @media (hover: hover) {
      &:hover {
        &:not(:disabled) {
          background-color: $white;
        }
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: $white;
      }
    }
  }

  // Size
  &.large {
    padding: 20px 30px;
    font-size: 26px;
    line-height: 28px;
  }

  &.medium {
    padding: 10px 22px;
    font-size: 18px;
    line-height: 28px;

    @media only screen and (max-width: $tablet) {
      padding: 12px 16px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &.small {
    padding: 14px 18px;
    font-size: 14px;
    line-height: 16px;
  }

  // Width
  .full {
    width: 100%;
  }

  // Type

  &.icon-transparent {
    gap: 8px;
    padding: 0;
    background: transparent;
    color: $black;
    border: none;

    .icon {
      flex-shrink: 0;
    }

    .text {
      text-align: left;
      max-width: 140px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
