@import 'mixins';
@import 'variables';
@import 'theme/theme';
@import 'animation';

* {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  --mat-dialog-transition-duration: 0ms !important;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color) transparent;

  ::-moz-focus-outer,
  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  letter-spacing: $font-spacing-base;
  color: $black;
  transition: opacity 2.5s;
  visibility: visible;
  opacity: 1;
  background: var(--body-color);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $tablet) {
    font-size: 16px;
  }

  --body-color: #0f1014;
  --header-color: #0d0e12;
  --sidebar-color: #0d0e12;
  --border-color: #1f212a;
  --scrollbar-color: #26272f;
  --title-color: #636674;
  --bg-color: #0f0f0f;
  --wrapper-color: #282828;
  --sb-item: #fff;
  --btn-bg: transparent;
  --btn-bg-hover: rgba(143, 155, 179, 0.08);
  --wrapper-border-color: #1a1b21;
  --text-color: #f5f5f5;
  --icon-color: #8f9bb3;
  --text-hover-color: #ffffff;
  --icon-hover-color: #ffffff;
  --header-shadow-color: rgb(45 50 66 / 50%);
  --input-bg-color: #202326;
  --input-border-color: #464852;
  --input-border: #464852;
  --input-border-hover: #464852;
  --input-text: rgba(255, 255, 255, 0.8);
  --input-editor-text: rgba(0, 0, 0, 0.8);
  --table-border: rgba(255, 255, 255, 0.2);
  --table-icon: rgba(255, 255, 255, 0.8);
  --box-shadow: rgb(2 2 2 / 70%);
  --img-filter: brightness(0) invert(1);
  --accent: #0495ee;
  --accent2: #029e77;
  --wb: #fff;

  ::ng-deep {
    .cdk-overlay-pane {
      .mat-mdc-select-panel {
        background-color: var(--wrapper-color) !important;
      }
    }
  }

  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  background: var(--bg-color);
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color) transparent;

  .app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .main {
    flex: 1 0 auto;
    width: 100%;
  }

  .footer,
  .header {
    flex: none;
  }

  .container {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1170px;
    width: 100%;
  }

  .pages__wrapper {
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .main__wrapper {
      height: 100%;
      display: flex;
      width: 100%;
      flex: 1;
    }
  }

  .header__wrapper {
    z-index: 100;
  }

  .sidebar_wrapper {
    background-color: var(--wrapper-color);
    box-shadow: 0 remc(8) remc(16) 0 rgb(44 51 73 / 10%);
    color: var(--text-color);
    width: remc(215);
    transition: all 0.4s;
    @media screen and (max-width: 1200px) {
      width: remc(57);
    }
    @media screen and (max-width: 576px) {
      width: remc(0);
    }

    &.expanded {
      width: remc(57);
    }
  }

  .middle {
    flex: 1;
    width: calc(100% - remc(307));
    position: relative;
    padding: remc(15) remc(30);
    z-index: 1;
    @media screen and (max-width: 992px) {
      padding: remc(15);
    }
  }

  .mat-dialog-container {
    padding: 0 !important;
  }

  .template-prev {
    width: 100%;
    height: 800px;
  }
}

a {
  font-family: $font-family-primary;
  color: $font-color-default;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
  font-family: $font-family-primary;
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

blockquote {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

fieldset {
  border: none;
  padding: 0;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

input,
textarea,
select,
button {
  font-family: $font-family-primary;
}

input[autocomplete='off']::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input {
  -webkit-padding-start: 0;

  &::-webkit-credentials-auto-fill-button {
    position: absolute;
    right: 0;
    top: 30%;
  }
}

input[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

input[type='password'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

input,
ng-select {
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.edit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }

  &__form {
    width: 50%;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  &__info {
    width: 49%;
    padding: 30px;
    @media screen and (max-width: 576px) {
      width: 100%;
      margin-top: 20px;
    }

    &__list {
      margin-top: 20px;
      box-shadow: 0px 4px 20px var(--box-shadow);
      border-radius: 10px;
      padding: 15px 30px;

      .list__title {
        font-size: 20px;
        color: var(--text-color);
        margin: 0 0 40px 0;

        &__text {
          margin-right: 15px;
          @media screen and (max-width: 576px) {
            margin-right: 0;
            margin-bottom: 15px;
            display: block;
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.alerts {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1999;

  &__item {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}

._tabs {
  .tab {
    display: none;

    &.active {
      display: block;
    }
  }
}

.tox-tinymce-aux {
  z-index: 100 !important;
}

.mb10 {
  margin-bottom: 10px;
}

.content-wrapper {
  padding: 30px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.baseline {
  align-items: baseline;
}

.fb__title {
  font-size: 20px;
  color: var(--text-color);
  margin: 0 0 40px 0;
}

.w100 {
  width: 100%;
}

.mr15 {
  margin-right: 15px;
}

.mr30 {
  margin-right: 30px;
}

.mb15 {
  margin-bottom: 15px;
}

.mt30 {
  margin-top: 30px;
}

.ml15 {
  margin-left: 15px;
}

.mdc-dialog__surface {
  border-radius: 10px !important;
}

.cdk-overlay-container {
  background: rgba(0, 0, 0, 0.6) !important;
  z-index: 9999;
}

.cdk-global-overlay-wrapper {
  overflow: auto;
}

.block {
  padding: 10px;
  border: 1px dashed #1e2027;
  margin-bottom: 15px;
}

.blocks__title {
  margin-bottom: 20px;
  font-size: 20px;
}

.block2 {
  padding: 3px;
  border: 1px dashed #1e2027;

  &.bg-green {
    background: #50ab64;
  }
}

.st-btn {
  margin-bottom: 20px;
  display: block;
}

.buttonH56px {
  button {
    height: 56px;
  }
}

.form_title {
  margin: 20px 0 40px 0;
  font-size: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.invisible {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.dashboard-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    width: 400px;
    height: auto;
  }
}

.list-image {
  width: 120px;
  max-height: 100px;
  object-fit: cover;
  height: auto;
}

.cdk-overlay-pane, .mat-mdc-dialog-container {
  max-width: 1000px !important;
}
