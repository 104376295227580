//colors
$black: #000000;
$gray: #526773;
$white: #ffffff;
$light-blue: #e2ecf2;
$blue50: #f1f5f9;
$bg: $light-blue;
$mint: #b9ddd4;
$mint-light: #dceee9;
$header-gradient: linear-gradient(90deg, rgba(163, 203, 230, 1) 0%, rgb(95, 252, 175) 100%);
$red: #c82323;

$font-color-default: $black;
$font-family-primary: 'Inter', sans-sarif;
$font-spacing-base: -0.02em;
$font-size-base: 14px;
$line-height-base: 24px;
$font-size-small: 15px;
$line-height-small: 20px;
$font-weight-base: 400;

$sidebar-width: 265px;
$sidebar-width-exp: 80px;
$header-height: 74px;

//breakpoints
$tiny: 480px;
$small: 768px;
$tablet: 1024px;
$medium: 1280px;
$large: 1441px;
$m-large: 1850px;
$x-large: 2560px;
