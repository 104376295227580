@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.fadeIn {
  opacity: 0;
  transition: opacity;

  &.is-visible {
    opacity: 1;
  }
}

.slideLeft {
  opacity: 0;
  transform: translateX(-10%);
  transition: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateX(0);
  }
}
.slideLeftSm {
  opacity: 0;
  transform: translateX(-2%);
  transition: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideRight {
  opacity: 0;
  transform: translateX(10%);
  transition: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideRightSm {
  opacity: 0;
  transform: translateX(2%);
  transition: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateX(0);
  }
}
.slideBottom {
  opacity: 0;
  transform: translateY(10%);
  transition: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}
.slideTopLg {
  opacity: 0;
  transform: translateY(-20%);
  transition: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideTop {
  opacity: 0;
  transform: translateY(-10%);
  transition: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideTopSm {
  opacity: 0;
  transform: translateY(-5%);
  transition: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.scaleX {
  transform: scaleX(0);
  transition: transform;

  &.is-visible {
    transform: scaleX(1);
  }
}
